<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <iframe
          width="100%"
          src="https://www.youtube.com/embed/y5aNruZeAkQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div class="green-header-3-text text-center">Julie</div>

        <!-- /embed-responsive -->
      </div>
      <div class="col-md-4">
        <iframe
          width="100%"
          src="https://www.youtube.com/embed/tiyBIy4dVK8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div class="green-header-3-text text-center">Steve</div>

        <!-- /embed-responsive -->
      </div>
      <div class="col-md-4">
        <iframe
          width="100%"
          src="https://www.youtube.com/embed/UjlbLWwiKcA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div class="green-header-3-text text-center">Jane</div>

        <!-- /embed-responsive -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>