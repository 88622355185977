<template>
  <div>
    <div
      class="hero-image mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="hero-text">
        <h1>Your Personalised Health Retreat</h1>
      </div>
    </div>
    <featured-in></featured-in>
    <div class="body-content mx-auto">
      <!-- Main header Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto text-center">
            <div class="green-header-2-text">
              Your Personalised Health Retreat experience - tailored to your
              needs and goals
            </div>
            <p>
              Are you struggling with health concerns such as diabetes,
              arthritis, gut problems, hormone imbalance or managing your
              weight?
            </p>

            <p>
              Are you looking to reduce your stress levels and boost your
              wellbeing?
            </p>

            <p>
              Your Amchara Personalised Health retreat experience is designed
              with your personal health needs and goals in mind.
            </p>

            <p>
              You will be immersed in a supportive and nurturing environment
              that enables you to switch off, relax, detox and kickstart your
              health journey.
            </p>

            <p>
              Our mission is to help you 'Change for Good' and empower you to
              improve your lifestyle and health.
            </p>

            <h5 class="mt-5">
              <strong
                >Over the last 10 years we have been helping people like you to
                achieve their health goals with our whole person, Personalised
                Health approach:</strong
              >
            </h5>

            <ul class="home-bullet-points-left text-start mt-5 mb-5">
              <li class="p-list-item mb-3 mb-md-0">
                Enjoy freshly prepared food and juices tailored for your
                nutritional needs
              </li>
              <li class="p-list-item mb-3 mb-md-0">
                Revitalise and re-energise your body and mind with state of the
                art spa facilities, health treatments and exercise classes
              </li>
              <li class="p-list-item mb-3 mb-md-0">
                Learn and grow with experienced Personalised Health
                practitioners' 1:1 psychological support, health and wellbeing
                coaching, educational talks plus raw food preparation classes
              </li>
              <li class="p-list-item mb-3 mb-md-0">
                Feel empowered to make long-lasting, sustainable changes to your
                daily life and health outcomes
              </li>
            </ul>
            <div class="green-header-2-text">Amchara's Approach</div>

            <p class="darkgreen-text text-center mb-0">
              <i>
                <strong
                  >In the 21st century there are many factors that influence our
                  health & wellbeing:</strong
                >
              </i>
            </p>
          </div>
        </div>

        <div class="row text-center my-5">
          <div class="col-12 col-md-6 mx-auto">
            <img
              width="100%"
              src="@/assets/images/pages/personalised-health/360-circle-new.jpg"
              alt="360 circle image"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-9 mx-auto text-center">
            <p>
              <strong
                >360&deg; Health means taking a complete, whole person, tailored
                approach to you as an individual.</strong
              >
            </p>
            <p>
              We work with you to review your relationship with nutrition
              together with interactions between genetic, environmental and
              lifestyle factors, which can influence both your long term health
              and the development of complex chronic disease.
            </p>
            <p><strong>Functional Medicine is Personalised Health.</strong></p>
            <p>
              Amchara’s experienced Personalised Health practitioners combine
              naturopathic principles with Functional Medicine.
            </p>
            <p>
              Your Personalised Health retreat can include advanced Functional
              Medicine laboratory tests to measure your genetics, hormones and
              your gut health, enabling you to better understand your individual
              circumstances and achieve optimal health.
            </p>
            <p>
              Functional Medicine recognises health not simply as the absence of
              disease; we all have the potential to create our own good health.
            </p>
            <p>
              Conventional medicine tends to have a disease-centred focus,
              looking at symptoms in isolation rather than cause, whereas
              Functional Medicine aims to determine the root causes of health
              problems and put them right.
            </p>
            <p>
              Our Personalised 360&deg; Health approach can empower you to make
              long lasting, sustainable change, achieve optimal health, and
              boost your wellbeing and happiness.
            </p>
            <p>Contact us today to find out how we can help you.</p>
          </div>
        </div>

        <div class="row mt-5 mb-3 text-center">
          <div class="col-md-10 mx-auto">
            <router-link
              to="/enquiries"
              class="btn btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</router-link
            >
          </div>
        </div>
      </div>

      <div class="my-3">&nbsp;</div>
      <hr width="80%" />
      <personalised></personalised>
      <div class="my-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Vimeo videos Section -->
      <div class="container-fluid my-5">
        <div class="row">
          <div class="col-md-12 mx-auto mb-5">
            <div class="green-header-2-text text-center">
              How have our clients benefited from an Amchara retreat?
            </div>
          </div>
        </div>
        <video-testimonials></video-testimonials>
      </div>
      <trip-advisor></trip-advisor>
      <div class="my-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Optional extras Section -->
      <div class="container-fluid mb-5">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center mb-5">
              Optional extras to enhance your stay
            </div>
            <!-- Circle Slider -->
            <div class="container mt-3 circle_slider" data-slider-id="1">
              <div class="row noselect">
                <div class="col-auto mx-auto">
                  <div class="mx-auto">
                    <!-- small green outer circle left -->
                    <div
                      class="green_circle outer_circle d-none d-lg-inline-block"
                      data-position="outer_left"
                    >
                      <div class="green_circle_outer_left_text"></div>
                      <div class="green_circle_outer_arrow"></div>
                    </div>
                    <!-- large green inner circle left -->
                    <div
                      class="green_circle inner_circle d-none d-lg-inline-block"
                      data-position="inner_left"
                    >
                      <div class="green_circle_inner_left_text"></div>
                      <div class="green_circle_inner_arrow"></div>
                    </div>
                    <div class="circle_slider_mobile_elements">
                      <!-- left arrow on mobiles -->
                      <div
                        class="slider-arrow d-lg-none mx-3"
                        data-rotate="left"
                      >
                        <i
                          class="fa fa-angle-left fa-4x"
                          style="color: grey"
                        ></i>
                      </div>
                      <!-- large selected centre circle -->
                      <div class="selected_circle selected_circle_blue">
                        <div
                          class="selected_circle_text selected_circle_text_blue"
                        ></div>
                        <div
                          class="
                            selected_circle_arrow selected_circle_arrow_blue
                          "
                        ></div>
                      </div>
                      <!-- right arrow on mobiles -->
                      <div
                        class="slider-arrow d-lg-none mx-3"
                        data-rotate="right"
                      >
                        <i
                          class="fa fa-angle-right fa-4x"
                          style="color: grey"
                        ></i>
                      </div>
                    </div>
                    <!-- large green inner circle right -->
                    <div
                      class="green_circle inner_circle d-none d-lg-inline-block"
                      data-position="inner_right"
                    >
                      <div class="green_circle_inner_right_text"></div>
                      <div class="green_circle_inner_arrow"></div>
                    </div>
                    <!-- small green outer circle right -->
                    <div
                      class="green_circle outer_circle d-none d-lg-inline-block"
                      data-position="outer_right"
                    >
                      <div class="green_circle_outer_right_text"></div>
                      <div class="green_circle_outer_arrow"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-md-9 mx-auto">
                  <!-- large selected centre circle subheader and description -->
                  <div class="text-center">
                    <div
                      class="
                        selected_circle_subheader selected_circle_subheader_blue
                        my-3
                      "
                    ></div>
                    <div class="selected_circle_description"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="row">
              <div class="col-md-7 my-auto">
                <div class="row">
                  <div class="col-md-6">
                    <ul class="home-bullet-points-left">
                      <li class="p-list-item mb-3 mb-md-0">Health Mentoring</li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Aromatherapy Massage
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Lymphatic Drainage Massage
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Emotional Support and NLP Coaching
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Colonic Hydrotherapy
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">Swedish Massage</li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="home-bullet-points-right">
                      <li class="p-list-item mb-3 mb-md-0">
                        Deep Tissue Massage
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Hot Stone Massage
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">Sugar Body Scrub</li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Reflexology Foot Massage
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Blissful Head Massage
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">Reiki</li>
                    </ul>
                  </div>
                </div>
                <div class="row my-5 my-md-3 text-center">
                  <div class="col">
                    <router-link
                      to="/whats-included"
                      class="btn btn-green-gradient green-link-button"
                    >
                      Find out more
                      <br class="d-block d-md-none" />about our treatments
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-md-5 my-auto text-center">
                <img
                  width="800"
                  src="@/assets/images/pages/personalised-health/health-spa-image.jpg"
                  alt="Health spa image"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Tailored nutrition Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto text-center">
            <div class="green-header-2-text">
              Tailored nutrition - changing your relationship with food
            </div>

            <div class="orange-text mb-3" style="font-size: 2rem">
              <i
                >In the Western world our food has changed more in the last 60
                years than in the previous 10,000.</i
              >
            </div>
            <p>
              Research has shown that combinations of sugar and fat can be
              addictive and committing to healthy dietary changes can be
              challenging.
            </p>

            <p>
              Bad habits around these foods, and other addictive substances, are
              quick to form and hard to break.
            </p>

            <p>
              Your retreat will offer a variety of healthy food choices
              including raw, vegan, and Palaeolithic eating plans. Deeper
              detoxes may incorporate broths, soups, smoothies, juices as well
              as juice cleansing or water fasting.
            </p>

            <p>
              All our food is freshly prepared and organic wherever possible.
            </p>

            <p>
              We will review your current lifestyle and nutritional choices and
              work with you to create a Personalised Health plan.
            </p>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-5 my-auto text-center">
            <img
              width="800"
              src="@/assets/images/pages/personalised-health/tailored-nutrition.jpg"
              alt="Food options image"
              class="img-fluid"
            />
          </div>
          <div class="col-md-7 my-auto pl-5 pr-5">
            <p class="darkgreen-text mt-4 mb-4">
              Developing permanent healthy habits is supported with effective
              strategies including:
            </p>
            <ul class="home-bullet-points-left mb-4">
              <li class="p-list-item mb-3 mb-md-0">
                Working on a 1:1 basis with your Personalised Health
                practitioner and support coach to understand your food triggers.
              </li>
              <li class="p-list-item mb-3 mb-md-0">
                Creating positive options by adding in enjoyable, appealing
                alternatives.
              </li>
              <li class="p-list-item mb-3 mb-md-0">
                Exploring mindfulness principles to teach you to create a gap
                between the stimulus and response.
              </li>
              <li class="p-list-item mb-3 mb-md-0">
                Participating in health educational workshops and food
                demonstrations to help you make the change process sustainable
                in your everyday life.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Relationship with food Section -->
      <div class="container-fluid mb-4">
        <div class="row">
          <div class="col-md-9 mx-auto text-center mt-5">
            Your health team will work with you to create your tailored
            nutrition plan for your retreat and after you leave, helping you
            ‘Change for Good’.
          </div>
        </div>

        <div class="row mt-4 text-center">
          <div class="col">
            <router-link
              to="/tailored-nutrition"
              class="btn btn-green-gradient green-link-button"
            >
              Find out more
              <br class="d-block d-md-none" />about tailored nutrition
            </router-link>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Fasting Section -->
      <div class="container-fluid">
        <div class="row mb-3">
          <div class="col-md-9 mx-auto text-center">
            <div class="green-header-2-text">Fasting - The Ultimate Detox</div>
            <p class>
              Fasting facilitates powerful, rapid and safe detoxification and
              stimulates the body’s natural healing processes. There are many
              types of fasting - our Personalised Health team will discuss which
              type is suitable for you, dependent on your personal medical
              history and health goals.
            </p>

            <p>
              <img
                width="600"
                src="@/assets/images/pages/personalised-health/types-of-fasting-2.jpg"
                alt="Types of fasting image"
                class="img-fluid mt-4"
              />
            </p>

            <div class="green-header-2-text">Why Fast?</div>
            <p>
              When you fast your body goes into ketosis, which means you draw
              energy from your natural energy reserves - fat - rather than from
              food.
            </p>
            <p>
              One key advantage of fasting is it reduces IGF-1, an immune marker
              that has been associated with increased risk of cancer, arthritis
              and diabetes.
            </p>
            <p>
              Your body has a number of mechanisms to naturally detox, but over
              time toxins can build up. Fasting as part of your detox retreat
              can boost your body’s immune system, brain function and
              metabolism, as well as improving the health of your skin and
              helping you lose weight.
            </p>
            <p>
              Working with your Personalised Health team can help kickstart your
              journey to optimal health.
            </p>

            <div class="container-fluid mb-4">
              <div class="row">
                <div class="col-md-12 my-auto text-center">
                  <!--<div class="green-header-2-text">Types of fasting</div>
                  <p>
              Within your programme, the type of fasting chosen is
              <br />tailored to your health goals and current medical status.
            </p>-->
                  <p class="mt-4 mb-5">
                    <router-link
                      to="/juice-fasting"
                      class="btn btn-green-gradient green-link-button"
                      >Find out more about fasting</router-link
                    >
                  </p>
                </div>
                <!--<div class="col-md-6 text-center">
                  <p><img src="@/assets/images/pages/personalised-health/types-of-fasting.jpg" width="400" alt="Types of fasting" class="img-fluid"></p>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />
      <locations></locations>
      <div class="mt-3 mb-3">&nbsp;</div>
      <featured-mags></featured-mags>
      <div class="container mt-5">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <router-link
              to="/enquiries"
              class="btn d-inline-block btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</router-link
            >
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- 360 Degree Approach Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto">
            <div class="green-header-2-text text-center">
              Further personalising your stay
            </div>
            <p class="darkgreen-text text-center">
              Enhance your experience with our 360° health tests
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="row text-center">
              <div class="col-12 col-md-5 mx-auto my-5">
                <img
                  width="100%"
                  src="@/assets/images/pages/personalised-health/360-triangle.jpg"
                  alt="360 triangle image"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 mx-auto text-center">
            <p>
              To support you to achieve 360° optimal health we operate a series
              of functional tests and can produce a personalised health plan
              that is tailored to your needs and goals. These advanced tests are
              not readily available on the NHS and include urine, saliva, blood
              and stool analysis. The different packages we offer can examine
              your hormones, gut health and genetic health in detail. Your tests
              are sent to our partner laboratory for results processing,
              allowing us to identify your unique ‘fingerprint’ of health.
            </p>
            <p class="orange-text">
              Once we understand your lifestyle and functional health needs,
              your Personalised Health Practitioner can provide you with a
              long-term nutrition and lifestyle solution that works for your
              unique needs.
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12 mx-auto text-center">
            <a
              href="https://www.amchara360.com"
              class="btn btn-green-gradient green-link-button m-4"
            >
              Find out more about
              <br class="d-block d-md-none" />our 360° health tests
            </a>
          </div>
        </div>
      </div>

      <hr width="80%" />
      <testimonials></testimonials>
      <div class="my-3">&nbsp;</div>
      <!--<hr width="80%" />
      <instagram></instagram>-->
      <div class="mb-5"></div>
    </div>
    <!-- body-content End -->

    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import Personalised from "./page-partials/Personalised";
import TripAdvisor from "./page-partials/TripAdvisor";
import FeaturedMags from "./page-partials/FeaturedMags";
import Locations from "./page-partials/Locations";
import Testimonials from "./page-partials/Testimonials";
import Instagram from "./page-partials/Instagram";
import NewsletterCta from "./page-partials/NewsletterCta";
import VideoTestimonials from "./page-partials/VideoTestimonials";

export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        i => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        i => i.slug == this.$route.path.replace("/", "")
      ).description
      // titleTemplate: null,
    };
  },
  data() {
    return {
      bgImages: [
        require("@/assets/images/pages/personalised-health/header-image.jpg")
      ]
    };
  },
  mounted() {
    var sliders = {
      slider_1: {
        outer_left: {
          title: "Colon Cleansing",
          subheader: "",
          description:
            "Over time, undigested waste accumulates in the colon, leading to reduced ability to absorb essential nutrients and increasing the risk of a hampered immune function. Cleansing your colon improves your health by removing toxins and easing your bowel movements, allowing for increased energy, kickstarting weight loss and encouraging a whole body detox."
        },
        inner_left: {
          title: "Prescribed Super Supplements",
          subheader: "",
          description:
            "To reach optimal health, you can include our optional super-supplementation range in your Personalised Health plan. We offer highly nutritional supplements on retreat including wheatgrass, blue-green algae, Aloe ferox, spirulina, turmeric, reishi mushroom, maca and mineral blend. Our Health Practitioners can work with you to develop a specific supplement programme to suit your needs."
        },
        middle: {
          title: "Health and Spa Treatments",
          subheader: "",
          description:
            "<p>The rhythmic strokes and pressure applied to muscles, tissues and organs during a massage can help stimulate the circulatory system, releasing toxins from the body and aiding in the detoxification process.</p><p>We also offer specialised reiki sessions, which can encourage healing and mobility in the body, whilst reducing feelings of stress and anxiety.</p><p>Health and emotional support can help you make a long-lasting, positive change to your health outcomes on your journey to optimal health."
        },
        inner_right: {
          title: "Tailored Nutrition Programme",
          subheader: "",
          description:
            "We will review your current lifestyle and food habits and work with you to create a Personalised Health plan to suit your individual needs. Our retreats offer a variety of healthy food options, including raw, vegan, vegetarian and Paleolithic choices. You also have the option of soups, smoothies, juices and broths if you prefer a deeper detox or wish to fast."
        },
        outer_right: {
          title: "1:1 Sessions",
          subheader: "",
          description:
            "To help you facilitate change and identify ways to incorporate your new healthy lifestyle habits when you return home, you can benefit from 1:1 coaching, hypnotherapy and neuro linguistic programming (NLP)."
        }
      }
    };

    $(document).ready(function() {
      // on page load
      $(".circle_slider").each(function() {
        var sliderID = $(this).data("slider-id");

        if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
          $(this)
            .find(".green_circle_outer_left_text")
            .html(sliders["slider_" + sliderID]["outer_left"]["title"]);
        }
        $(this)
          .find(".green_circle_inner_left_text")
          .html(sliders["slider_" + sliderID]["inner_left"]["title"]);
        $(this)
          .find(".selected_circle_text")
          .html(sliders["slider_" + sliderID]["middle"]["title"]);
        $(this)
          .find(".green_circle_inner_right_text")
          .html(sliders["slider_" + sliderID]["inner_right"]["title"]);

        if (sliders["slider_" + sliderID]["outer_right"] != undefined) {
          $(this)
            .find(".green_circle_outer_right_text")
            .html(sliders["slider_" + sliderID]["outer_right"]["title"]);
        }

        $(this)
          .find(".selected_circle_subheader")
          .html(sliders["slider_" + sliderID]["middle"]["subheader"]);
        $(this)
          .find(".selected_circle_description")
          .html(sliders["slider_" + sliderID]["middle"]["description"]);
      });

      // desktop event listener
      $(".green_circle").click(function() {
        var this_circle_slider = $(this).closest(".circle_slider");
        var sliderID = this_circle_slider.data("slider-id");
        var position = $(this).data("position");

        // switch the newly selected object with the previously selected object
        var previously_selected_obj = sliders["slider_" + sliderID]["middle"];
        var newly_selected_obj = sliders["slider_" + sliderID][position];
        sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        sliders["slider_" + sliderID][position] = previously_selected_obj;

        // now update the objects on screen
        $(this)
          .find(".green_circle_" + position + "_text")
          .html(previously_selected_obj.title);
        $(this_circle_slider)
          .find(".selected_circle_text")
          .html(newly_selected_obj.title);
        $(this_circle_slider)
          .find(".selected_circle_subheader")
          .html(newly_selected_obj.subheader);
        $(this_circle_slider)
          .find(".selected_circle_description")
          .html(newly_selected_obj.description);
      });

      // mobile event listener
      $(".slider-arrow").click(function() {
        var this_circle_slider = $(this).closest(".circle_slider");
        var sliderID = this_circle_slider.data("slider-id");
        var sliderRotate = $(this).data("rotate");

        // move all objects up or down one depending on whether left (down) or right (up) arrow was pressed
        if (sliderRotate == "left") {
          var newly_selected_obj = sliders["slider_" + sliderID]["inner_left"];
          if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
            // 5 circle slider
            sliders["slider_" + sliderID]["inner_left"] =
              sliders["slider_" + sliderID]["outer_left"];
            sliders["slider_" + sliderID]["outer_left"] =
              sliders["slider_" + sliderID]["outer_right"];
            sliders["slider_" + sliderID]["outer_right"] =
              sliders["slider_" + sliderID]["inner_right"];
          } else {
            // 3 circle slider
            sliders["slider_" + sliderID]["inner_left"] =
              sliders["slider_" + sliderID]["inner_right"];
          }
          sliders["slider_" + sliderID]["inner_right"] =
            sliders["slider_" + sliderID]["middle"];
          sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        } else if (sliderRotate == "right") {
          var newly_selected_obj = sliders["slider_" + sliderID]["inner_right"];
          if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
            // 5 circle slider
            sliders["slider_" + sliderID]["inner_right"] =
              sliders["slider_" + sliderID]["outer_right"];
            sliders["slider_" + sliderID]["outer_right"] =
              sliders["slider_" + sliderID]["outer_left"];
            sliders["slider_" + sliderID]["outer_left"] =
              sliders["slider_" + sliderID]["inner_left"];
          } else {
            // 3 circle slider
            sliders["slider_" + sliderID]["inner_right"] =
              sliders["slider_" + sliderID]["inner_left"];
          }
          sliders["slider_" + sliderID]["inner_left"] =
            sliders["slider_" + sliderID]["middle"];
          sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        }

        // now update the objects on screen
        // including the (hidden on mobile) green circles incase
        // they are on desktop and expand the vieport
        if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
          $(this_circle_slider)
            .find(".green_circle_outer_left_text")
            .html(sliders["slider_" + sliderID]["outer_left"]["title"]);
        }
        $(this_circle_slider)
          .find(".green_circle_inner_left_text")
          .html(sliders["slider_" + sliderID]["inner_left"]["title"]);
        $(this_circle_slider)
          .find(".selected_circle_text")
          .html(sliders["slider_" + sliderID]["middle"]["title"]);
        $(this_circle_slider)
          .find(".green_circle_inner_right_text")
          .html(sliders["slider_" + sliderID]["inner_right"]["title"]);
        if (sliders["slider_" + sliderID]["outer_right"] != undefined) {
          $(this_circle_slider)
            .find(".green_circle_outer_right_text")
            .html(sliders["slider_" + sliderID]["outer_right"]["title"]);
        }

        $(this_circle_slider)
          .find(".selected_circle_subheader")
          .html(sliders["slider_" + sliderID]["middle"]["subheader"]);
        $(this_circle_slider)
          .find(".selected_circle_description")
          .html(sliders["slider_" + sliderID]["middle"]["description"]);
      });
    });
  },
  components: {
    FeaturedIn,
    Personalised,
    TripAdvisor,
    FeaturedMags,
    Locations,
    Testimonials,
    Instagram,
    NewsletterCta,
    VideoTestimonials
  }
};
</script>

<style>
</style>