<template>
  <!-- Personalised For You Section -->
  <div class="container-fluid mb-5">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <div class="green-header-2-text text-center">Personalised to you</div>
        <div class="row py-5">
          <div class="col-md-7 my-auto px-5 px-md-3 pe-lg-5">
            <p class="medium-text text-center text-md-start mb-3">
              Maximise your Personalised Health detox retreat experience with
              the following:
            </p>
            <div class="row">
              <div class="col-md-6">
                <ul class="home-bullet-points-left">
                  <li class="p-list-item mb-0">Tailored nutrition</li>
                  <li class="p-list-item mb-0">
                    Juice or water fasting
                  </li>
                  <li class="p-list-item mb-0">
                    Freshly prepared healthy food
                  </li>
                  <li class="p-list-item mb-0">
                    Fresh supplements (such as wheatgrass, sprouts, naturopathic
                    remedies)
                  </li>
                  <li class="p-list-item mb-0">
                    Functional Medicine tests (gut health, genetics, hormones)
                  </li>
                  <li class="p-list-item mb-0">
                    Active exercise classes
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="home-bullet-points-right">
                  <li class="p-list-item mb-0">
                    Health treatments (including colonics, coffee enemas and
                    massages)
                  </li>
                  <li class="p-list-item mb-0">
                    Spa facilities (including infrared sauna & swimming pool)
                  </li>
                  <li
                    class="p-list-item mb-0">
                    1:1 psychological support & health & wellbeing coaching
                  </li>
                  <li class="p-list-item mb-0">
                    Educational talks with experts including raw food
                    preparation classes
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-5 mt-5 my-md-auto text-center">
            <img
              width="800"
              src="@/assets/images/pages/home/personalised-image-2.jpg"
              alt="Personalised image"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3 text-center">
      <div class="col">
        <div class="">
          <router-link
            to="/enquiries"
            class="btn btn-orange-gradient enquire-now-button"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bgImages: [require("@/assets/images/logos/generic/tick-icon.jpg")],
    };
  },
};
</script>

<style>
</style>